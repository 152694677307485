// <!-- API -->
import { ref, computed, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useECNBCache } from '@/hooks/store/useECNBCache';

// <!-- UTILITIES -->
import debounce from 'just-debounce-it';
import isNil from 'lodash-es/isNil';

// <!-- COMPOSABLES -->
import { UploadFormConfig } from '~CSVUploader/hooks/form/useUploadForm';
import { useMappingProfileIndex } from '@/hooks/cache/useMappingProfileIndex';

// <!-- TYPES -->
import { Store } from 'vuex';
import { ECNBState } from '@/store/types/ECNBStore';
import { MappingProfile } from '@/models/mappings/MappingProfile';
import { UploadRecord } from '@/store/types/uploader/state/UploadRecord';
import { Emoji } from '@/utils/emoji';

/**
 * Data class representing form state.
 */
export class SelectProfileFormState {
    /**
     * Construct form state instance from an existing resource.
     * @param {import('@/models/mappings/MappingProfile').MappingProfileResource} resource
     * @returns {SelectProfileFormState}
     */
    static fromResource(resource) {
        const state = SelectProfileFormState.blank();
        return state.deserialize(resource);
    }

    /**
     * Construct resource instance from an existing form state.
     * @param {SelectProfileFormState} state
     * @returns {import('@/models/mappings/MappingProfile').MappingProfileResource}
     */
    static toResource(state) {
        return state.serialize();
    }

    /**
     * Perform deep copy of an existing source state reference.
     * @param {SelectProfileFormState} source
     * @returns {SelectProfileFormState}
     */
    static fromSource(source) {
        const mirror = source.clone();
        return mirror;
    }

    /**
     * Create a blank, empty state without defaults, for a new mapping profile.
     * @returns {SelectProfileFormState}
     */
    static blank() {
        const state = new SelectProfileFormState();
        state.preset = null;
        state.id = null;
        state.name = null;
        state.settings = {
            offset: 0,
            limit: 0,
            headers: true,
            delimiter: ',',
            decimal: '.',
        };
        state.rules = [
            { index: null, format: 'yyyy-MM-dd', target: 'date' },
            { index: null, format: 'hh:mm:ss', target: 'time' },
            { index: null, format: 'C', target: 'temp' },
            { index: null, format: '%', target: 'rh' },
        ];
        return state;
    }

    /**
     * Instantiate the form state with default values.
     */
    constructor() {
        /**
         * If non-null, this state is a copy of an existing mapping profile.
         * @type {String | null}
         */
        this.preset = null;

        /**
         * Resource identifier. `-1` indicates a new resource.
         * @type {Number}
         */
        this.id = -1;

        /**
         * Preset name. May not be unique.
         * @type {String}
         */
        this.name = 'New Mapping Profile';

        /**
         * Settings to apply when mapping the field data.
         * @type {{ offset: Number, limit: Number, headers: Boolean, delimiter: "," | "\t", decimal: "." | "," }}
         */
        this.settings = {
            offset: 0,
            limit: 0,
            headers: true,
            delimiter: ',',
            decimal: '.',
        };

        /**
         * Rules to apply when mapping field data.
         * @type {{ index: Number, format: String, target: String }[]}
         */
        this.rules = [
            { index: 0, format: 'yyyy-MM-dd hh:mm:ss', target: 'date' },
            { index: 1, format: 'yyyy-MM-dd hh:mm:ss', target: 'time' },
            { index: 2, format: 'C', target: 'temp' },
            { index: 3, format: '%', target: 'rh' },
        ];
    }

    /**
     * Perform a deep clone of this state and produce a new instance.
     * @returns {SelectProfileFormState}
     */
    clone() {
        // Create copy from blank start.
        const copy = SelectProfileFormState.blank();
        copy.preset = this.preset;
        // Copy literals.
        copy.id = this.id;
        copy.name = this.name;
        // Copy settings.
        copy.settings = Object.assign({}, this.settings);
        // Copy rules.
        copy.rules = this.rules.map((rule) => Object.assign({}, rule));
        // Return copy.
        return copy;
    }

    /**
     * Serialize state into a mapping profile resource object.
     * @param {import('@/models/mappings/MappingProfile').MappingProfileResource} resource
     * @returns {this}
     */
    deserialize(resource) {
        if (resource) {
            this.preset = String(resource.id);
            // Copy literals.
            this.id = resource.id;
            this.name = resource.name;
            // Copy settings.
            // @ts-ignore
            this.settings = Object.assign({}, resource.settings);
            // Copy rules in order.
            const order = ['date', 'time', 'temp', 'rh'];
            this.rules = order
                .map((target) => {
                    const match = resource.rules.find(
                        (r) => r.target === target
                    );
                    return Object.assign({}, match);
                })
                .filter((r) => !isNil(r));
        }
        // Return this instance.
        return this;
    }

    /**
     * Serialize state into a mapping profile resource object.
     * @returns {import('@/models/mappings/MappingProfile').MappingProfileResource}
     */
    serialize() {
        const resource = new MappingProfile().toResource();
        resource.id = this.id ?? -1;
        resource.name = this.name;
        resource.settings = Object.assign(resource.settings, this.settings);
        const order = ['date', 'time', 'temp', 'rh'];
        // @ts-ignore
        resource.rules = order
            .map((target) => {
                const match = this.rules.find((r) => r.target === target);
                return Object.assign({}, match);
            })
            .filter((r) => !isNil(r));
        return resource;
    }
}

/**
 * Data class representing the preview contents.
 */
export class ContentsPreviewState {
    /**
     * Default contents used when dataset has no data.
     */
    static getDefaultContents() {
        return [
            {
                line: 1,
                data: ['A1', 'B1', 'C1', 'D1'],
            },
            {
                line: 2,
                data: ['A2', 'B2', 'C2', 'D2'],
            },
            {
                line: 3,
                data: ['A3', 'B3', 'C3', 'D3'],
            },
        ];
    }

    /**
     * Construct state from contents.
     * @param {Array<{ line: Number, data: Array<String> | { date: String, temp: Number, rh: Number, dp: Number, pi: Number } }>} contents
     */
    constructor(contents = null) {
        /**
         * @type {Array<{ line: Number, data:  Array<String> | { date: String, temp: Number, rh: Number, dp: Number, pi: Number } }>}
         */
        this.contents = contents ?? ContentsPreviewState.getDefaultContents();
    }

    /**
     * Get dimension lengths.
     * @returns {[ rows: Number, cols: Number ]}
     */
    get dim() {
        return /** @type {[ rows: Number, cols: Number ]} */ (
            this.getShape(null)
        );
    }

    /**
     * Get minimum row or column count, inclusive. Not zero-based.
     */
    get min() {
        return {
            row: 1,
            col: 1,
        };
    }

    /**
     * Get maximum row or column count, exclusive. Not zero-based.
     */
    get max() {
        const shape = this.getShape(null);
        return {
            row: shape[0] + 1,
            col: shape[1] + 1,
        };
    }

    /**
     * Get rowwise, colwise, or table shape lengths.
     * @param {1 | 2 | null} axis
     * @returns {[ rows: Number, cols: Number ] | Number}
     */
    getShape(axis = null) {
        if (Array.isArray(this.contents[0].data)) {
            const hasContents = !isNil(this.contents);
            const hasRows = hasContents && this.contents.length > 0;
            const hasCols =
                hasRows && (this.contents[0]?.data?.length ?? 0) > 0;
            const rows = hasRows ? this.contents.length : 0;
            const cols = hasCols ? this.contents[0]?.data?.length ?? 0 : 0;
            if (axis === 1) {
                return rows;
            }
            if (axis === 2) {
                return cols;
            }
            return [rows, cols];
        } else {
            const hasContents = !isNil(this.contents);
            const hasRows = hasContents && this.contents.length > 0;
            const hasCols =
                hasRows &&
                (Object.keys(this.contents[0]?.data)?.length ?? 0) > 0;
            const rows = hasRows ? this.contents.length : 0;
            const cols = hasCols
                ? Object.keys(this.contents[0]?.data)?.length ?? 0
                : 0;
            if (axis === 1) {
                return rows;
            }
            if (axis === 2) {
                return cols;
            }
            return [rows, cols];
        }
    }

    /**
     * Get array of line numbers.
     * @returns {String[]}
     */
    getLineNumbers() {
        return this.contents.map((c) => {
            return String(c.line);
        });
    }

    /**
     * Get column labels for data.
     * @param {Boolean} [useHeaders]
     */
    getColumnLabels(useHeaders) {
        if (Array.isArray(this.contents[0]?.data)) {
            const row = this.contents[0].data;
            if (!useHeaders) {
                return row.map((_, index) => `Column ${index + 1}`);
            } else {
                return row
                    .map((r) => (r.length > 10 ? r.slice(0, 10) : r))
                    .map((r) => r.toLocaleUpperCase())
                    .map((r) => r.trim())
                    .map((r, index) =>
                        r.length === 0
                            ? `Column ${index + 1}`
                            : `[${index + 1}]: ${r}`
                    );
            }
        } else {
            const keys = Object.keys(this.contents[0].data);
            return keys.map((key) => {
                switch (key) {
                    case 'date':
                        return 'Date';
                    case 'temp':
                        return 'Temperature';
                    case 'rh':
                        return 'Relative Humidity';
                    case 'dp':
                        return 'Dew Point';
                    case 'pi':
                        return 'Preservation Index';
                }
            });
        }
    }

    /**
     * Get array of row data, with values entered under column names.
     * @returns {Record<String, any>[]} Get array of records, with each value wrapped in a fieldname.
     */
    getRowData() {
        // Map row data, wrapped in the appropriate field.
        // ts-ignore
        return this.contents.map(({ line, data }, index) => {
            if (Array.isArray(data)) {
                /** @type {[ field: String, value: String ]} */
                const indexer = ['index', String(index)];
                /** @type {([ field: String, value: String ])[]} */
                const entries = data.map((cell, index) => {
                    /** @type {[ field: String, value: String ]} */
                    const entry = [String(index), String(cell)];
                    return entry;
                });
                return Object.fromEntries([indexer, ...entries]);
            } else {
                /** @type {[ field: String, value: String ]} */
                const indexer = ['index', String(index)];
                /** @type {([ field: String, value: String ])[]} */
                const entries = Object.keys(data).map((cell, index) => {
                    /** @type {[ field: String, value: String ]} */
                    const entry = [String(index), String(data[cell])];
                    return entry;
                });
                return Object.fromEntries([indexer, ...entries]);
            }
        });
    }
}

/**
 * Prepare state, computed properties, and methods for the select profile form.
 * @param {UploadFormConfig} form
 */
// ts-ignore
export const useSelectProfileForm = (form) => {
    /**
     * Internal method for instantiating state.
     */
    const useState = () => {
        /**
         * Is the form modal visible?
         * @type {V.Ref<Boolean>}
         */
        const open = ref(false);

        /**
         * Specifies the currently set prefered mode.
         * Note: Displayed form may be overriden if context requires it.
         * @type {V.Ref<'edit' | 'view'>}
         */
        const mode = ref('edit');

        /**
         * Filename pointer to the current target record.
         * @type {V.Ref<String>}
         */
        const target = ref(null);

        /**
         * The clean form state.
         * @type {V.Ref<SelectProfileFormState>}
         */
        const cleanData = ref(SelectProfileFormState.blank());

        /**
         * The dirty form state.
         * @type {V.Ref<SelectProfileFormState>}
         */
        const dirtyData = ref(cleanData.value.clone());

        /**
         * Fetching statuses.
         * @type {V.Ref<Set<'index' | 'profile' | 'preview'>>}
         */
        const fetching = ref(new Set());

        // EXPOSE
        return {
            open,
            mode,
            target,
            cleanData,
            dirtyData,
            fetching,
        };
    };

    /**
     * Internal method for conditional computed properties.
     */
    const useStatus = () => {
        /**
         * Is the modal open?
         * @type {V.ComputedRef<Boolean>}
         */
        const isOpen = computed(() => {
            return state.open.value === true;
        });

        /**
         * Is editing currently allowed?
         * @type {V.ComputedRef<Boolean>}
         */
        const isEditingAllowed = computed(() => {
            const record = properties.targetRecord.value;
            return (
                !isNil(record) &&
                !record.isApplyingMappingProfile &&
                !record.isCreatingMappingProfile &&
                !record.isDatasetBatchIngesting &&
                !record.isDatasetBatchIngested
            );
        });

        /**
         * Is editing the currently preferred mode?
         * @type {V.ComputedRef<Boolean>}
         */
        const isEditingPreferred = computed(() => {
            return state.mode.value === 'edit';
        });

        /**
         * Is editing?
         * @type {V.ComputedRef<Boolean>}
         */
        const isEditing = computed(() => {
            const allowed = status.isEditingAllowed.value === true;
            const preferred = status.isEditingPreferred.value === true;
            return allowed && preferred;
        });

        /**
         * Is viewing?
         * @type {V.ComputedRef<Boolean>}
         */
        const isViewing = computed(() => {
            return !status.isEditing.value;
        });

        /**
         * Is the form fetching any data?
         * @type {V.ComputedRef<Boolean>}
         */
        const isFetching = computed(() => {
            const fetching = state.fetching.value;
            return !isNil(fetching) && fetching.size > 0;
        });

        /**
         * Is the form fetching the mapping profile index?
         * @type {V.ComputedRef<Boolean>}
         */
        const isFetchingIndex = computed(() => {
            const fetching = state.fetching.value;
            return !isNil(fetching) && fetching.has('index');
        });

        /**
         * Is the form fetching an individual mapping profile's data?
         * @type {V.ComputedRef<Boolean>}
         */
        const isFetchingProfile = computed(() => {
            const fetching = state.fetching.value;
            return !isNil(fetching) && fetching.has('profile');
        });

        /**
         * Is the form fetching preview data?
         * @type {V.ComputedRef<Boolean>}
         */
        const isFetchingPreview = computed(() => {
            const fetching = state.fetching.value;
            return !isNil(fetching) && fetching.has('preview');
        });

        /**
         * Does the target record exist?
         */
        const hasTargetRecord = computed(() => {
            const target = state.target.value;
            const records = store.state.uploader.data.records;
            return (
                !isNil(target) &&
                target !== '' &&
                !isNil(records) &&
                records.has(target)
            );
        });

        const isPEMFile = computed(() => {
            return (
                hasTargetRecord.value === true &&
                properties.targetRecord.value.isMarkedAsPEM === true
            );
        });

        const isExistingProfile = computed(() => {
            const profile = properties.targetRecord.value.mappingProfile.value;
            return (
                profile &&
                !!profile.id &&
                !isNaN(profile.id) &&
                profile.id !== -1 &&
                properties.profileIndex.value.has(profile.id)
            );
        });

        const isNewProfile = computed(() => {
            const profile = properties.targetRecord.value.mappingProfile.value;
            return (
                profile &&
                !!profile.id &&
                !isNaN(profile.id) &&
                profile.id === -1
            );
        });

        const isPresetSelected = computed(() => {
            const preset = state.dirtyData.value.preset;
            return !isNil(preset);
        });

        const isNewPresetSelected = computed(() => {
            const data = state.dirtyData.value;
            const preset = data.preset;
            const id = data.id;
            return preset === '-1' || id === -1;
        });

        const isHeadersIncluded = computed(() => {
            const dirty = state.dirtyData.value;
            return dirty.settings.headers === true;
        });

        return {
            isOpen,
            isEditingAllowed,
            isEditingPreferred,
            isEditing,
            isViewing,
            isFetching,
            isFetchingIndex,
            isFetchingPreview,
            isFetchingProfile,
            hasTargetRecord,
            isPEMFile,
            isExistingProfile,
            isNewProfile,
            isPresetSelected,
            isNewPresetSelected,
            isHeadersIncluded,
        };
    };

    /**
     * Internal method for instantiating properties.
     */
    const useProperties = () => {
        /**
         * @type {V.ComputedRef<UploadRecord>}
         */
        const targetRecord = computed(() => {
            if (status.hasTargetRecord.value) {
                const filename = state.target.value;
                const records = store.state.uploader.data.records;
                const record = records.get(filename);
                return record;
            }
            return null;
        });

        /**
         * @type {V.ComputedRef<String>}
         */
        const targetFilename = computed(() => {
            const record = properties.targetRecord.value;
            return record?.filename ?? '<Missing Record>';
        });

        /**
         * @type {V.ComputedRef<String>}
         */
        const targetLocationPath = computed(() => {
            const record = properties.targetRecord.value;
            if (record.location.exists) {
                const location = record.location.value;
                const { path = null, name = null } = location;
                const segments = [
                    path?.length > 0 ? path : '<Missing Hierarchy>',
                    name?.length > 0 ? name : '<Missing Name>',
                ];
                return segments.join('/');
            }
            return '<Missing Location>';
        });

        /**
         * @type {V.ComputedRef<String>}
         */
        const targetLocationDisplayName = computed(() => {
            const record = properties.targetRecord.value;
            if (record.location.exists) {
                const location = record.location.value;
                return location?.label;
            }
            return '<Missing Location>';
        });

        /**
         * @type {V.ComputedRef<ContentsPreviewState>}
         */
        const targetContents = computed(() => {
            const contents = targetRecord.value.contents;
            if (isNil(contents) || contents.length === 0) {
                return new ContentsPreviewState(null);
            } else {
                return new ContentsPreviewState(contents);
            }
        });

        /**
         * Mapping index reference.
         * @type {V.ComputedRef<Map<Number, import('@/models/mappings/MappingProfile').MappingProfileResource>>}
         */
        const profileIndex = computed(() => {
            const cache = store.state.cache.profiles;
            const index = cache.index;
            return index;
        });

        /**
         * Get profile index preset options.
         */
        const presetIndexOptions = computed(() => {
            const index = profileIndex.value ?? new Map();
            const profiles = [...index.values()];
            return methods.getPresetOptions(profiles);
        });

        return {
            targetRecord,
            targetFilename,
            targetLocationPath,
            targetLocationDisplayName,
            targetContents,
            profileIndex,
            presetIndexOptions,
        };
    };

    /**
     * Internal method for instantiating handlers.
     */
    const useHandlers = () => {
        /**
         * Event invoked when a preset is changed.
         * @param {String} value
         * @param {import('@/components/sidebar/hooks/useDateRangeFilter').FormKitNode} node
         */
        const onPresetChanged = (value, node) => {
            // Do nothing if node is null.
            if (!node) {
                return;
            }

            // Cast value.
            const id = isNil(value) ? null : Number(value);

            // If preset is nil or not changed...
            if (isNil(value) || isNaN(id) || state.dirtyData.value.id === id) {
                // Do nothing.
                return;
            }

            // If preset is -1...
            if (id === -1) {
                console.log(`[preset::changed]`);
                // New mapping profile...
                const data = state.dirtyData.value.clone();
                data.preset = '-1';
                data.id = -1;
                data.name = 'New Mapping Profile';
                methods.setDirtyData(data);
            }

            // If value is existing id...
            if (properties.profileIndex.value.has(id)) {
                console.log(`[preset::changed]`);
                const profile = properties.profileIndex.value.get(id);
                if (!!profile) {
                    // Existing mapping profile...
                    const data = SelectProfileFormState.fromResource(profile);
                    data.preset = String(profile.id);
                    data.id = profile.id;
                    data.name = profile.name;
                    data.settings.headers = profile.settings.headers === true;
                    methods.setDirtyData(data);
                }
            }
        };

        /**
         * Event invoked when any non-preset field is changed.
         * @param {String} value
         * @param {import('@/components/sidebar/hooks/useDateRangeFilter').FormKitNode} node
         */
        // ts-ignore
        const onFieldChanged = (value, node) => {
            if (!node) {
                return;
            }

            // Ensure mapping profile points to a 'new' reference.
            if (state.dirtyData.value.preset !== '-1') {
                const profile = properties.profileIndex.value.get(
                    Number(state.dirtyData.value.preset)
                );
                const preset = SelectProfileFormState.fromResource(profile);
                const dirty = state.dirtyData.value;
                if (
                    preset.settings.offset != dirty.settings.offset ||
                    preset.settings.limit != dirty.settings.limit ||
                    preset.settings.headers != dirty.settings.headers ||
                    preset.settings.delimiter != dirty.settings.delimiter ||
                    preset.settings.decimal != dirty.settings.decimal ||
                    preset.rules?.[0].index != dirty.rules?.[0].index ||
                    preset.rules?.[0].format != dirty.rules?.[0].format ||
                    preset.rules?.[1].index != dirty.rules?.[1].index ||
                    preset.rules?.[1].format != dirty.rules?.[1].format ||
                    preset.rules?.[2].index != dirty.rules?.[2].index ||
                    preset.rules?.[2].format != dirty.rules?.[2].format ||
                    preset.rules?.[3].index != dirty.rules?.[3].index ||
                    preset.rules?.[3].format != dirty.rules?.[3].format
                ) {
                    // If any value has changed...
                    state.dirtyData.value.preset = '-1';
                }
            }

            // Refresh the preview...
        };

        return {
            onPresetChanged: debounce(onPresetChanged, 200),
            onFieldChanged: debounce(onFieldChanged, 200),
        };
    };

    /**
     * Internal method for instantiating methods.
     */
    const useMethods = () => {
        /**
         * Show the modal.
         */
        const showModal = () => {
            state.open.value = true;
        };

        /**
         * Hide the modal.
         */
        const hideModal = () => {
            state.open.value = false;
        };

        /**
         * Set form to prefer editing.
         */
        const preferEditing = () => {
            state.mode.value = 'edit';
        };

        /**
         * Set form to prefer viewing.
         */
        const preferViewing = () => {
            state.mode.value = 'view';
        };

        /**
         * Switch to viewing mode, init the clean data, and reset the dirty data.
         */
        const startView = () => {
            presetCleanData(properties.targetRecord.value.mappingProfile.value);
            resetDirtyData();
            preferViewing();
        };

        /**
         * Switch to editing mode, init the clean data, and reset the dirty data.
         */
        const startEdit = () => {
            presetCleanData(properties.targetRecord.value.mappingProfile.value);
            resetDirtyData();
            preferEditing();
        };

        /**
         * Cancel edit mode, switching back to the view mode.
         */
        const cancelEdit = () => {
            if (status.isEditing.value) {
                // Reset dirty data, if editing.
                resetDirtyData();
            }
            // Prefer viewing mode.
            preferViewing();
        };

        const closeModal = () => {
            // Cancel edit mode.
            cancelEdit();
            // Close the view mode.
            hideModal();
            // Unbind target.
            clearTarget();
        };

        /**
         * Select profile using the passed preset id.
         * @param {Number} preset
         */
        const selectProfile = (preset) => {
            const id = Number(preset);
            if (properties.profileIndex.value.has(id)) {
                const resource = properties.profileIndex.value.get(id);
                return SelectProfileFormState.fromResource(resource);
            }
            if (id === -1) {
                // New profile from blank slate.
                return SelectProfileFormState.blank();
            }
            // No preset selected.
            return null;
        };

        /**
         * Save the dirty data value as a resource.
         */
        const saveProfile = async () => {
            const data = state.dirtyData.value;
            const record = properties.targetRecord.value;
            if (!isNil(data.preset) && data.id !== -1) {
                const profile = selectProfile(Number(data.preset));
                await store.dispatch(
                    `uploader/data/selectRecordMappingProfile`,
                    {
                        filename: record.filename,
                        profile: profile.serialize(),
                    }
                );
            } else {
                await store.dispatch(
                    `uploader/data/selectRecordMappingProfile`,
                    {
                        filename: record.filename,
                        profile: data.serialize(),
                    }
                );
            }
            // Close the view/edit mode.
            hideModal();
            // Unbind target.
            clearTarget();
        };

        /**
         * Bind target filename.
         */
        const bindTarget = () => {
            return {
                /**
                 * Set target using exact filename.
                 * @param {String} filename
                 */
                byFilename: (filename) => {
                    state.target.value = filename;
                },
                /**
                 * Set target using specific record.
                 * @param {UploadRecord} record
                 */
                withRecord: (record) => {
                    state.target.value = record.filename;
                },
            };
        };

        /**
         * Unbind target.
         */
        const clearTarget = () => {
            state.target.value = null;
        };

        /**
         * Set fetching status.
         * @param {'index' | 'profile' | 'preview'} key
         * @param {Boolean} value
         */
        const setFetching = (key, value) => {
            const fetching = state.fetching.value;
            if (value === true) {
                state.fetching.value = fetching.add(key);
            } else {
                fetching.delete(key);
                state.fetching.value = fetching;
            }
        };

        /**
         * Set the clean data state.
         * @param {SelectProfileFormState} data
         */
        const setCleanData = (data) => {
            const update = data.clone();
            state.cleanData.value = update;
        };

        /**
         * Set the dirty data state.
         * @param {SelectProfileFormState} data
         */
        const setDirtyData = (data) => {
            const update = data.clone();
            state.dirtyData.value = update;
        };

        /**
         * Reset clean data to the stored resource, if one is available.
         * @param {import('@/models/mappings/MappingProfile').MappingProfileResource} resource Resource to prefill the clean data with.
         */
        const presetCleanData = (resource) => {
            const clean = SelectProfileFormState.fromResource(resource);
            setCleanData(clean);
        };

        /**
         * Reset dirty data to the clean data.
         */
        const resetDirtyData = () => {
            const clean = state.cleanData.value;
            const dirty = SelectProfileFormState.fromSource(clean);
            state.dirtyData.value = dirty;
        };

        /**
         * Fetch the profiles.
         * @param {Boolean} forceReload
         * @returns {Promise<Map<Number, import('@/models/mappings/MappingProfile').MappingProfileResource>>}
         */
        const fetchProfiles = async (forceReload = false) => {
            try {
                setFetching('index', true);
                await refreshMappingProfileIndex(forceReload);
                return properties.profileIndex.value;
            } finally {
                setFetching('index', false);
            }
        };

        /**
         * Mapping profile getters.
         */
        const getProfile = () => {
            return {
                /**
                 * Get mapping profile from an index by id.
                 * @param {Number} id
                 */
                byID: (id) => {
                    return properties.profileIndex.value.get(id);
                },
                /**
                 * Get mapping profile from a record.
                 * @param {UploadRecord} record
                 */
                fromRecord: (record) => {
                    return record.mappingProfile.value;
                },
            };
        };

        /**
         * @type {(context: import('@/utils/FormKitDebugger').FormKitFrameworkContext) => Promise<void>}
         */
        const toggleHeaders = async (context) => {
            console.log('toggle-headers');
            if (status.isEditing.value) {
                const current = context.value;
                const next = !current;
                context.node.input(next);
                await nextTick();
                const data = state.dirtyData.value;
                data.settings.headers = next;
                setDirtyData(data);
            }
        };

        /**
         * @param {import('@/models/mappings/MappingProfile').MappingProfileResource[]} profiles
         * @returns {{ label: String, value: String | null, attrs?: { disabled?: Boolean }}[]}
         */
        const getPresetOptions = (profiles = []) => {
            /**
             * Create an option from a profile resource.
             * @param {Number} id
             * @param {String} name
             * @param {Boolean} [disabled]
             */
            // ts-ignore
            const createOption = (id, name, disabled = null) => ({
                label: `Preset ${String(id)} (${name})`,
                value: String(id),
            });
            const placeholder = {
                label: `Select Preset`,
                value: null,
                attrs: {
                    disabled: true,
                },
            };
            const custom = {
                label: `New Preset`,
                value: '-1',
            };
            return [
                placeholder,
                custom,
                ...profiles.map((p) =>
                    createOption(p.id, p.name ?? '<Untitled Preset>')
                ),
            ];
        };

        /**
         * @param {'date' | 'time' | 'temp' | 'rh'} field
         */
        const getFieldIndexOptions = (field) => {
            const placeholder = {
                label: `Select Column`,
                value: null,
                attrs: { disabled: true },
            };
            const contents = properties.targetContents.value;
            const labels = contents.getColumnLabels().map((label, index) => ({
                label,
                value: String(index),
            }));
            switch (field) {
                case 'date':
                case 'time':
                case 'temp':
                case 'rh':
                default:
                    return [placeholder, ...labels];
            }
        };

        /**
         *
         * @param {'date' | 'time' | 'temp' | 'rh'} field
         */
        const getFieldFormatOptions = (field) => {
            const placeholder = {
                label: `Select Format`,
                value: null,
                attrs: { disabled: true },
            };
            switch (field) {
                case 'date':
                    return [
                        placeholder,
                        {
                            label: '1970-01-31',
                            value: 'yyyy-MM-dd',
                        },
                        {
                            label: '01-31-1970',
                            value: 'dd-MM-yyyy',
                        },
                        {
                            label: '31/01/1970',
                            value: 'dd/mm/YYYY',
                        },
                        {
                            label: '01/31/1970',
                            value: 'mm/dd/YYYY',
                        },
                        {
                            label: 'Jan. 31, 1970',
                            value: 'M. dd, YYYY',
                        },
                        {
                            label: 'January 31, 1970',
                            value: 'MM dd, YYYY',
                        },
                        {
                            label: '19703101T00:00:00Z',
                            value: 'YYYYMMDD',
                        },
                    ];
                case 'time':
                    return [
                        placeholder,
                        { label: '24:59', value: 'hh:mm' },
                        { label: '24:59:59', value: 'hh:mm:ss' },
                    ];
                case 'temp':
                    return [
                        placeholder,
                        { label: `Celsius (${Emoji.degree}C)`, value: 'C' },
                        { label: `Fahrenheit (${Emoji.degree}F)`, value: 'F' },
                        // COMMENTED OUT FOR NARA: { label: 'Kelvin (K)', value: 'K' },
                        // COMMENTED OUT FOR NARA: { label: `Rankine (${Emoji.degree}R)`, value: 'R' },
                    ];
                case 'rh':
                    return [
                        placeholder,
                        { label: `Percentage (%)`, value: '%' },
                    ];
                default:
                    return [placeholder];
            }
        };

        // EXPOSE
        return {
            showModal,
            hideModal,
            closeModal,
            startView,
            startEdit,
            cancelEdit,

            toggleHeaders,

            preferEditing,
            preferViewing,

            bindTarget,
            clearTarget,

            presetCleanData,
            setCleanData,
            resetDirtyData,
            setDirtyData,

            setFetching,
            fetchProfiles,
            getProfile,
            selectProfile,

            saveProfile,

            getPresetOptions,
            getFieldIndexOptions,
            getFieldFormatOptions,
        };
    };

    /**
     * @type {Store<ECNBState>}
     */
    const store = useStore();
    const cache = useECNBCache(store);
    const { refreshMappingProfileIndex } = useMappingProfileIndex(cache);
    const state = useState();
    const status = useStatus();
    const properties = useProperties();
    const methods = useMethods();
    const handlers = useHandlers();

    // EXPOSE
    return {
        store,
        state,
        status,
        properties,
        methods,
        handlers,
    };
};

// DEFAULT
export default useSelectProfileForm;
